import React, { useCallback } from 'react';
import type { FooterProps } from '@goodfynd/react-web.layout.footer';
import { Footer } from '@goodfynd/react-web.layout.footer';
import Head from 'next/head';

import CmsHeader from '../components/cms/CmsHeader';
import type { CmsHeaderProps } from '../components/cms/CmsHeader/types';
import LayoutFull from '../components/layout/LayoutFull';
import config from '../config';
import { useCmsPage } from '../hooks';
import { setPageTitle } from '../utils/base';
import {
  renderCmsBodyComponent,
  renderCmsTitleComponent,
} from '../utils/cms-util';
function Home(): JSX.Element {
  const { body, seo, header, title, footer } = useCmsPage('event-home-page');

  const renderBody = useCallback(
    () =>
      body?.map(({ fields, type }, index) => {
        switch (type) {
          default:
            return renderCmsBodyComponent(index, type, fields);
        }
      }),
    [body]
  );

  const renderTitle = useCallback(
    () => (
      <>
        {title?.map(({ fields, type }, index) =>
          renderCmsTitleComponent(index, type, fields)
        )}
      </>
    ),

    [title]
  );

  return (
    <>
      {seo && (
        <Head>
          <title>{setPageTitle('Home')}</title>
          <meta content={seo?.description} name="description" />
        </Head>
      )}

      <div style={{ paddingTop: header ? 64 : 0 }}>
        {header && <CmsHeader {...(header as unknown as CmsHeaderProps)} />}

        {renderTitle()}
        <div
          style={{
            marginTop: 40,
          }}
        >
          {renderBody()}
        </div>

        {footer && (
          <Footer
            drift_interaction_id={config.env.DRIFT_INTERACTION_ID}
            {...(footer as unknown as FooterProps)}
          />
        )}
      </div>
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <LayoutFull>{page}</LayoutFull>;
};

export default Home;
