import { styled } from '@goodfynd/react-web.theme';

export const StyledBody = styled('div', {
  flex: 1,
  fontSize: 16,
  maxWidth: 1440,
  '@smMax': {
    minWidth: '100%',
  },
  '@lgMin': {
    margin: '0 auto',
    minWidth: 1440,
  },
});

export const StyledPageContainer = styled('div', {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
});
