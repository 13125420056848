import type { PropsWithChildren } from 'react';
import React from 'react';

import { StyledPageContainer } from './styles';

export default function LayoutFull({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  return <StyledPageContainer>{children}</StyledPageContainer>;
}
